const toggleVisibility = (elementId, hideMenu = false) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.classList.toggle('show');
        if (hideMenu) {
            const menu = document.getElementById('menu');
            menu.classList.remove('show');
        }
    }
};

var intro = introJs();

if (!localStorage.getItem('introCompleted') && window.matchMedia("(min-width: 768px)").matches) {
    intro.setOptions({
        steps: [{
            title: 'Welcome to Tic Tac Toe Life!',
            intro: 'Welcome to TicTacToe.Life! Our goal is to provide a ad-free, clean, simple and fun experience for you to play Tic Tac Toe. Click "Next" to learn how to play!'
        },
        {
            element: document.querySelector('#board'),
            intro: 'This is the game board. You can play Tic Tac Toe here. The game is played on a 3x3 grid. You are X, and the bot is O. The first player to get 3 of their marks in a row wins!'
        },
        {
            element: document.querySelector('#scoreboard'),
            intro: 'This is the scoreboard. It keeps track of the score for each player. The player with the most wins is the winner!'
        },
        {
            element: document.querySelector('#new-game'),
            intro: 'This is the new game button. Click it to start a new game.'
        },
        {
            element: document.querySelector('#moon-icon'),
            intro: 'This is the theme icon. Click it to toggle between light and dark mode.'
        },
        {
            element: document.querySelector('#menu-icon'),
            intro: 'This is the menu icon. Click it to open the menu and change the bot difficulty.'
        },
        {
            element: document.querySelector('#bot-difficulty-button'),
            intro: 'This is the bot difficulty button. Click it to change the bot difficulty.',
            position: 'right'
        }],
    }).onbeforechange(async function (targetElement) {
        if (targetElement.id === 'bot-difficulty-button') {
            toggleVisibility('menu', false);

            return new Promise((resolve) => {
                setTimeout(resolve, 300);
            });
        }
        const body = document.querySelector('body');
        if (body.classList.contains('dark-theme')) {
            // Add css which makes .introjs-tooltip text color black
            const style = document.createElement('style');
            style.innerHTML = '.introjs-tooltiptext { color: black; } .introjs-tooltip-header .introjs-tooltip-title { color: black; }';
            document.head.appendChild(style);
        }
    }).onchange(function (targetElement) {
        if (targetElement.id === 'menu-icon' && intro._currentStep > intro._options.steps.indexOf(targetElement)) {
            toggleVisibility('menu', true);
        }
    }).oncomplete(function () {
        localStorage.setItem('introCompleted', 'true');
        toggleVisibility('menu', true);
    }).onexit(function () {
        localStorage.setItem('introCompleted', 'true');
        toggleVisibility('menu', true);
    }).start();
}